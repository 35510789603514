import Header from "../../component/Header/Header";

function RegisteredPage() {

  return (
    <div>
      <Header/>
      <div>
        <p className='subtitle'>Merci à vous !</p> 
        <p>Vous êtes maintenant un ambassadeur de la marque et faîtes parti des premiers à nous faire confiance.</p>
        <p>Nous vous préviendrons quand l’application mobile sera disponible sur les stores.</p>
        <p>A très vite sur Spoteet !</p>
      </div>
    </div>
  );
}

export default RegisteredPage;
